// eslint-disable-next-line no-unused-vars
import nitro from "./nitro.js";
import u from "./utils.js";

export default async function loadPage({ app_id, auth_token, setPage, setError, setLoading, setChannels, setAmount, setChannel, setChannelType }) {
    try {
        const { channel_types, amount, deposit_type } = await nitro.getChannels({
            setError,
            setLoading,
            app_id,
            auth_token,
        });

        if (!u.isNonEmptyArray(channel_types) || !u.isPositiveNumber(amount)) throw Error("Invalid channel_types or amount");

        const channel_type =
            channel_types?.length === 1
                ? channel_types[0]?.type || "upi"
                : channel_types?.length > 1 && deposit_type === "bank_transfer"
                ? "bank_transfer"
                : "upi";

        const channel_type_options = channel_types.find((x) => x.type === channel_type)?.options || [];

        setChannel(channel_type_options?.[0]);

        nitro.logImpression(
            {
                setError,
                setLoading,
                app_id,
                auth_token,
            },
            channel_type_options?.[0]
        );

        setChannelType(channel_type);
        setChannels(channel_types);
        setAmount(amount);
        setPage("pay");
    } catch (error) {
        console.log(error?.message);
        setError(true);
    } finally {
        setLoading(false);
    }
}
