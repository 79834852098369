/* eslint-disable react/prop-types */
import React, { useEffect } from "react";

import { useAppContext } from "../../AppContext";
import { ChevronRightIcon } from "@heroicons/react/24/outline";
import { BanknotesIcon } from "@heroicons/react/20/solid";

import UpiIcon from "../icons/UpiIcon";
import u from "../../functions/utils";

export default function ChannelOptions() {
    let { channel, setChannel, channelType } = useAppContext();

    // useEffect(() => console.log('channelType', channelType), [channelType, channel])

    // if (!u.isNonEmptyArray(channelType?.options)) return null;

    function UpiOption({ address }) {
        return (
            <div className="flex items-center space-x-5 p-2">
                <div className="bg-blu bg-opacity-10 p-2 rounded-lg">
                    <UpiIcon className="h-6 w-6 text-white" />
                </div>
                <span className="font-bold text-sm md:text-base ">{address}</span>
            </div>
        );
    }

    function BankTransferOption({ bankName, accountNumber, ifscCode }) {
        return (
            <div className="flex items-center space-x-5 p-1">
                <div className="bg-blu bg-opacity-10 p-2 rounded-lg">
                    <BanknotesIcon className="h-6 w-6 text-blu_med" />
                </div>
                <div className="space-y-1">
                    <div>
                        <span className="font-bold text-sm">{bankName}</span>
                    </div>
                    <div className="font-extralight text-xs">{accountNumber}</div>
                    <div className="font-extralight text-xs">{ifscCode}</div>
                </div>
            </div>
        );
    }

    function ChannelOption({ c }) {
        if (!c) return;

        const OptionTypeComponent = {
            upi: <UpiOption address={c.upi_address} />,
            bank_transfer: <BankTransferOption bankName={c.bank_name} accountNumber={c.bank_account_number} ifscCode={c.bank_ifsc_code} />,
        }[c.type] || <div></div>;

        const selectOption = () => {
            console.log(c);
            setChannel(c);
        };

        return (
            <div
                onClick={selectOption}
                className={`group cursor-pointer relative flex w-full rounded-lg bg-white text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 sm:text-sm sm:leading-6 fade-in ${
                    c.channel_id === channel?.channel_id ? "ring-blu ring-1" : ""
                }  hover:ring-blu hover:ring-1 hover:bg-opacity-5 hover:bg-blu`}
            >
                <div className="flex-grow p-2">{OptionTypeComponent}</div>
                <div className="flex items-center p-3 bg-transparent text-blu_med group-hover:text-blu_bold min-h-full rounded-r-md transition-opacity duration-300">
                    <ChevronRightIcon className="h-5 w-5" />
                </div>
                {/* <div className="absolute rounded-md inset-0 border-2 border-transparent transition-all duration-300"></div> */}
            </div>
        );
    }

    return (
        <>
            <div className="relative my-[24px]">
                <div aria-hidden="true" className="absolute inset-0 flex items-center">
                    <div className="w-full border-t border-gray-300" />
                </div>
                <div className="relative flex justify-center">
                    <span className="bg-white px-2 text-sm text-gray-500">Select an Option</span>
                </div>
            </div>
            <div className={`grid gap-4 grid-cols-1`}>
                {channelType?.options?.map((x) => (
                    <ChannelOption key={x.channel_id} c={x} />
                ))}
            </div>
        </>
    );
}
