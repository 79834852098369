import axios from "axios";
import u from "./utils.js";

export async function sendRequest(endpoint, body = {}, state) {
    try {
        const url = process.env[`REACT_APP_ENDPOINT_URL`];

        if (!u.isNonEmptyString(state.app_id) || !u.isNonEmptyString(state.auth_token) || !u.isNonEmptyString(url)) throw Error("invalid request params");
        const endpointUrl = `${url}${endpoint}/${state.app_id}`;

        //

        const response = await axios.post(endpointUrl, body, {
            headers: {
                Authorization: `Bearer ${state.auth_token}`,
                "Content-Type": "application/json",
            },
            validateStatus: function (status) {
                return true;
            },
        });

        if (response?.status > 400 || !u.isPositiveNumber(response?.status)) throw Error("endpoint did not return ok status");

        return response?.data || {};
    } catch (error) {
        console.log(error?.message);
        state.setError(true);
        return {};
    } finally {
        // state.setLoading(false)
    }
}

/**
 * Gets channel options
 * @returns {Promise}
 */

async function getChannels(state) {
    return await sendRequest("/get-channels", {}, state);
}

/**
 * Gets upload url
 * @returns {Promise}
 */

async function getUploadUrl(state) {
    return await sendRequest("/get-upload-url", {}, state);
}

/**
 * Submit utr
 * @returns {Promise}
 */

async function submitUtr(state, body) {
    return await sendRequest("/submit-deposit", body, state);
}

/**
 * Submit utr
 * @returns {Promise}
 */

async function logImpression(state, channelDetails = {}) {
    const { channel_id, type, qr_displayType, qr_image, qr_link } = channelDetails || {};

    let body = {
        channel_id,
        type,
    };

    if (type === "upi") {
        body.qr_displayType = qr_displayType;
        body.has_qr_image = u.isNonEmptyString(qr_image);
        body.has_qr_link = u.isNonEmptyString(qr_link);
    }

    return await sendRequest("/channel-impression", body, state);
}

export default {
    getChannels,
    getUploadUrl,
    submitUtr,
    logImpression,
};
